import moment, { Moment } from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslations } from '../../hooks/useTranslations'
import { RenderIf } from '../RenderIf'
import { TimeHeader, TimeHeaderContainer } from './styled'
import './styled.css'
import { WeekHandler } from './WeekHandler'

import { TimePicker } from './TimePicker'
import { useMySelector } from '../../hooks/useMySelector'

const TIME_START = 6,
  TIME_END = 18

type CalendarProps = {
  hideCalendar?: boolean
  showTime?: boolean
  minDateGap?: number
  onUpdate?: (value: Moment) => void
}

export const Calendar: FC<CalendarProps> = ({
  hideCalendar = false,
  showTime = false,
  minDateGap = 0,
  onUpdate,
}) => {
  const t = useTranslations()
  const { restaurants } = useMySelector((state) => state)

  const [value, setValue] = useState<Moment>(
    moment().add(minDateGap, 'days').set({ h: TIME_START })
  )

  useEffect(() => {
    if (onUpdate) {
      onUpdate(value)
    }
  }, [value])

  const setNewDate = (newDate: Moment) => {
    setValue((prevValue) =>
      moment(prevValue).set({ D: newDate.date(), M: newDate.month() })
    )
  }

  const setTime = (name: string, value: number) => {
    if (name === 'hours') {
      setHours(value)
    }
    if (name === 'minutes') {
      setMinutes(value)
    }
  }

  const setHours = (newHours: number) => {
    setValue((prevValue) => moment(prevValue).set({ h: newHours }))
    if (newHours >= TIME_END) {
      setValue((prevValue) => moment(prevValue).set({ m: 0 }))
    }
  }

  const setMinutes = (newMinutes: number) => {
    setValue((prevValue) => {
      if (+moment(prevValue).format('HH') < TIME_END) {
        return moment(prevValue).set({ m: newMinutes })
      }
      return prevValue
    })
  }

  const getMinDate = () => {
    return moment().add(minDateGap, 'days')
  }

  // const timeLimits = { start: 6, end: 9 }
  const timeLimits = useMemo(() => {
    const day = value.format('dddd').toLocaleLowerCase()

    if (!restaurants[0]?.schedule?.[day]) {
      return null
    }

    let earliestStart: moment.Moment | null = null
    let latestEnd: moment.Moment | null = null

    for (const restaurant of restaurants) {
      const schedule = restaurant.schedule[day]
      if (schedule) {
        const [startTime, endTime] = schedule.split(' - ')

        const startMoment = moment(startTime, 'HH:mm:ss')
        const endMoment = moment(endTime, 'HH:mm:ss')

        if (!earliestStart || startMoment.isBefore(earliestStart)) {
          earliestStart = startMoment
        }

        if (!latestEnd || endMoment.isAfter(latestEnd)) {
          latestEnd = endMoment
        }
      }
    }

    return {
      start: earliestStart?.hour(),
      end: latestEnd?.hour(),
    }
  }, [value, restaurants])

  return (
    <div data-testid="calendar_test-id">
      <RenderIf condition={!hideCalendar}>
        <WeekHandler
          value={value}
          onChange={setNewDate}
          minDate={getMinDate()}
        />
      </RenderIf>
      <RenderIf condition={showTime}>
        <TimeHeader>{t('clickCollect.chooseTime')}</TimeHeader>
        <TimeHeaderContainer>
          <TimePicker
            hours={value.hours()}
            minutes={value.minutes()}
            //
            hoursStart={timeLimits?.start || TIME_START}
            hoursEnd={timeLimits?.end ? timeLimits?.end - 1 : TIME_END}
            //
            handleTime={setTime}
          />
        </TimeHeaderContainer>
      </RenderIf>
    </div>
  )
}
