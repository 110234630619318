import { FC, useState } from 'react'
import moment from 'moment'
import { Button } from '../../components/Button'
import { Spinner } from '../../components/Spinner'
import { RenderIf } from '../../components/RenderIf'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useMySelector } from '../../hooks/useMySelector'
import { Calendar } from '../../components/Calendar'
import { SelectContainer } from './styledComponents'
import { SelectTime } from './styled'

type TimePickerProps = {
  increaseStep?: () => void
}

export const TimePicker: FC<TimePickerProps> = ({ increaseStep }) => {
  // Hooks
  const t = useTranslations()
  const { isAppInit } = useMySelector((state) => state.app)
  const { leadTimeDays, leadTimeHours } = useMySelector(
    (state) => state.app.clickCollectConfig
  )

  // State
  const [dateTime, setDateTime] = useState(moment())

  // Functions
  const handleAccept = () => {
    handleLocalStorage({
      pickUpTime: dateTime.utc().format(),
    })
    increaseStep?.()
  }

  const getMinDateGap = () => {
    const timeNow = moment().format('HH:mm')
    const timeLead = moment(leadTimeHours, 'HH:mm').format('HH:mm')
    if (timeNow > timeLead) {
      return leadTimeDays + 1
    }
    return leadTimeDays
  }

  return (
    <SelectContainer>
      <SelectTime>{t('clickCollect.choosePickUpTime')}</SelectTime>
      <RenderIf condition={isAppInit}>
        <Calendar
          showTime
          minDateGap={getMinDateGap()}
          onUpdate={setDateTime}
        />
        <Button buttonType="common" width="100%" onClick={handleAccept}>
          {t('buttons.accept')}
        </Button>
      </RenderIf>
      <RenderIf condition={!isAppInit}>
        <Spinner type="alternate" />
      </RenderIf>
    </SelectContainer>
  )
}

export default TimePicker
